<template>
  <v-dialog
    persistent
    max-width="800px"
    v-model="dialog"
  >
    <v-card>
      <v-card-title>{{ dash.title }}</v-card-title>
      <v-divider />
      <v-card-text v-if="dash.disclaimer && dash.disclaimer !== ''" class="mt-3">
        {{ dash.disclaimer }}
      </v-card-text>
      <v-card-text class="pt-5 pb-0">
        <v-text-field
          :label="this.$t('title')"
          v-model="dash.title"
        />
      <v-card-text>
        <v-textarea
		      auto-grow
          v-model="dash.description"
          :readonly="true"
          :class="{ 'custom-cursor': true }"
          outlined
        />
      </v-card-text>
        <v-slider
          v-model="dash.cols"
          min="1"
          max="12"
          :label="$t('size')"
        />
      </v-card-text>
      <v-divider />
      <v-card-text
        class="mt-5 mb-0"
        v-if="dashType !== null && typeof dashType !== 'undefined' && dashType.configFields !== null && dashType.configFields.length > 0 && !dashType.configFields.includes(null)"
      >
        <template v-for="configField in dashType.configFields">
          <div
            :key="configField.key + '-title'"
            class="mb-2"
          >
            {{ $t('additionalSettingsForDash') }}
          </div>
          <ConfigField
            :key="configField.key"
            :field="configField"
            :instance="dash"
          />
        </template>
      </v-card-text>
      <v-card-text
        class="mt-5"
        v-else
      >
        {{ $t('dashProvidesNoAdditionalSettings') }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="dash.uuid"
          dark
          :disabled="processing"
          :loading="processing"
          color="red"
          @click="deleteDash"
        >
          {{ $t('delete') }}
        </v-btn>
        <v-btn
          v-if="dash.uuid"
          :disabled="processing || dashType === null || typeof dashType === 'undefined'"
          :loading="processing"
          color="primary"
          @click="save"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-else
          :disabled="processing"
          :loading="processing"
          color="primary"
          @click="create"
        >
          {{ $t('create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import ConfigField from '@/components/ConfigField.vue'

export default {
    name: 'DashConfigDialog',
    props: {
      dash: {
        type: Object,
        default: () => {}
      }
    },
    components: {
      ConfigField
    },
    data () {
      return {
        dialog: true,
        processing: false,
        cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    },
    watch: {
      dialog() {
        this.$emit('input', this.dialog)
      }
    },
    computed: {
      dashType() {
        return this.$store.state.dashTypes.find(dashType => dashType.namespace === this.dash.namespace)
      }
    },
    methods: {
      cancel() {
        this.dialog = false
      },
      deleteDash() {
        this.processing = true
        this.$store.dispatch('deleteDash', {
            uuid: this.dash.uuid
          }).then(deleted => {
            this.$emit('deleted', deleted)
          }).finally(() => {
            this.processing = false
          })
      },
      create() {
        this.processing = true
        this.$store.dispatch('createDash', {
            title: this.dash.title,
            description: this.dash.description,
            namespace: this.dash.namespace,
            cols: this.dash.cols,
            data: this.dash.data
          }).then(createdDash => {
            this.$emit('created', createdDash)
          }).finally(() => {
            this.processing = false
          })
      },
      save() {
        this.processing = true
        this.$store.dispatch('updateDash', {
            uuid: this.dash.uuid,
            title: this.dash.title,
            description: this.dash.description,
            cols: this.dash.cols,
  					data: this.dash.data
          }).then((updatedDash) => {
            this.$emit('updated', updatedDash)
          }).finally(() => {
            this.processing = false
        })
      }
    }
}
</script>

<style scoped>

.custom-cursor {
	pointer-events: none;
  	cursor: auto; /* or any other cursor style you want */
}

</style>